import clsx from 'clsx'
import { useCallback, useRef, useState } from 'react'
import { passwordStrength } from 'check-password-strength'
import { useEventListener } from 'usehooks-ts'

import * as validation from '~/validation'

import { CheckmarkOutline } from '~/icons/checkmarks'
import { useValidatedForm } from '~/providers/form-provider'

export type PasswordStrengthProps = {
	password: string
}

export default function PasswordStrength({ password }: PasswordStrengthProps) {
	const strengthLevel = passwordStrength(password).id
	const strengthIssues = validation.passwordStrength
		.safeParse(password)
		.error?.issues?.map(issue => issue.message)
	const allAspects = validation.passwordStrengthAspects
	const satisfiedAspects = validation.passwordStrengthAspects.filter(
		aspect => strengthIssues && !strengthIssues.includes(aspect)
	)

	return (
		<div>
			<div className='flex flex-col gap-2'>
				<StrengthMeter level={strengthLevel} />
				{allAspects.map(aspectKey => (
					<p
						key={aspectKey}
						className='flex items-center justify-start gap-2'
					>
						<CheckmarkOutline
							className={clsx(
								satisfiedAspects.includes(aspectKey) &&
									'text-support-success'
							)}
						/>
						{
							validation.passwordStrengthMessages[
								aspectKey as keyof typeof validation.passwordStrengthMessages
							]
						}
					</p>
				))}
			</div>
		</div>
	)
}

type StrengthMeterProps = {
	level: number
}

function StrengthMeter({ level }: StrengthMeterProps) {
	return (
		<div className='mt-2 flex h-1 w-full flex-nowrap gap-[2px]'>
			{Array.from({ length: 3 }).map((_, i) => (
				<StrengthMeterBar key={i} activated={i < level} />
			))}
		</div>
	)
}

type StrengthMeterBarProps = {
	activated: boolean
}

function StrengthMeterBar({ activated }: StrengthMeterBarProps) {
	return (
		<div
			className={clsx(
				'flex-grow bg-border-subtle-00',
				activated && '!bg-support-success'
			)}
		></div>
	)
}
