import PasswordStrength from './password-strength'
import TextInput, { TextInputProps } from './text-input'
import { Anchor } from './link'
import { useCallback, useState } from 'react'
import { View, ViewOff } from '~/icons/view'
import Tooltip from './tooltip'

export type PasswordInputProps = {
	strength?: boolean
	helper?: boolean
} & TextInputProps

export default function PasswordInput({
	strength,
	helper,
	...props
}: PasswordInputProps) {
	const [isRevealed, setIsRevealed] = useState(false)
	const [password, setPassword] = useState('')

	const handleInput = useCallback((value: string) => {
		setPassword(value)
	}, [])

	return (
		<div className='relative overflow-visible'>
			<TextInput
				{...props}
				type={isRevealed ? 'text' : 'password'}
				onInput={handleInput}
				icon={
					<Tooltip
						tooltip={
							!isRevealed ? 'Show password' : 'Hide password'
						}
						disabled={props.disabled ?? false}
						gapSize={0.5}
					>
						<div
							className='flex size-12 cursor-pointer items-center justify-center'
							onClick={() => setIsRevealed(!isRevealed)}
						>
							{isRevealed ? <ViewOff /> : <View />}
						</div>
					</Tooltip>
				}
			/>
			{helper && (
				<Anchor
					$size='secondary'
					href='/password/forgot'
					className='absolute right-0 top-0'
				>
					Forgot?
				</Anchor>
			)}
			{strength && <PasswordStrength password={password} />}
		</div>
	)
}
